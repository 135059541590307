import CanvasPuzzle from './CanvasPuzzle';


function App() {
  return (
    <CanvasPuzzle/>
  );
}

export default App;
